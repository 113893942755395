.profile-activity {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .profile-activity h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
  }
  
  .profile-activity ul {
    list-style: none;
    padding: 0;
  }
  
  .profile-activity li {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  