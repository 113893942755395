.profile-info {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .profile-info h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
  }
  
  .profile-info p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .edit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  