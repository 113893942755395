#ad-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0px;
  border-radius: 15px;
  box-shadow: 10px 24px 16px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
}

.ads-container {
  display: grid;
  gap: 24px;
  padding: 24px;
  width: 100%;
}

/* Desktop */
@media (min-width: 1024px) {
  .ads-container.vertical {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .ads-container.horizontal {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .ads-container.vertical {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .ads-container.horizontal {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile */
@media (max-width: 767px) {
  .ads-container.vertical {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 16px;
  }
  
  .ads-container.horizontal {
    grid-template-columns: 1fr;
  }
}

.ad-item {
  height: 100%;
}

.ad-item.with-image {
  grid-row: span 2;
}

@media (max-width: 600px) {
  #ad-list {
    grid-template-columns: repeat(2, 180px); /* Siempre dos columnas de 150px */
    gap: 10px;
  }
  .ads-container {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    padding: 8px;

  }
}

.ad-size-normal {
  grid-column: span 1;
  grid-row: span 1;
}

.ad-size-largo {
  grid-column: span 2;
  grid-row: span 1;
}

.ad-size-largo-vertical {
  grid-column: span 1;
  grid-row: span 2;
  height: 360px;
}

.ad-size-grande {
  grid-column: span 2;
  grid-row: span 2;
  height: 360px;
}

.ad-size-gigante {
  grid-column: span 2;
  grid-row: span 3;
  height: 540px;
}

.ad-size-normal .ad-card__title, 
.ad-size-normal .ad-card__description {
  font-size: 1rem;
}

.ad-size-largo .ad-card__title, 
.ad-size-largo .ad-card__description {
  font-size: 1.2rem;
}

.ad-size-grande .ad-card__title, 
.ad-size-grande .ad-card__description {
  font-size: 1.5rem;
}

.ad-size-gigante .ad-card__title, 
.ad-size-gigante .ad-card__description {
  font-size: 1.8rem;
}