@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.wrapper {
  display: inline-flex;
  list-style: none;
  position: fixed;
  bottom: 20px;
}

.wrapper .icon {
  position: relative;
  background: #25d366;
  border-radius: 50%;
  padding: 0px;
  margin-right: 10px;
  width: 80px;
  height: 80px;
  transition: all 0.2scubic-beizer(
      0.68,
      -0.55,
      cubic-bezier(0.68, -0.55, 0.265, 1.55)
    );
  font-size: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0, 10px 10px rgba(0, 0, 0, 0.1);
}

.wrapper .icon i {
  transition: color 0.2s;
}

.wrapper .facebook svg {
  color: #3b5998;
}

.wrapper .linkedin svg {
  color: #0077b5;
}

.wrapper .instagram svg {
  color: #e4405f;
}

.wrapper .whatsapp svg {
  color: #fff;
}

.wrapper .tiktok svg {
  color: #2c3e50;
}

.wrapper .icon:hover svg {
  color: #fff;
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 1rem;
  background: #fff;
  color: #fff;
  padding: 5px, 8px;
  border-radius: 5px;
  opacity: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .tooltip::before {
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #3b5998;
  color: #fff;
}

.wrapper .linkedin:hover,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip::before {
  background: #0077b5;
  color: #fff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #fff;
}

.wrapper .whatsapp:hover,
.wrapper .whatsapp:hover .tooltip,
.wrapper .whatsapp:hover .tooltip::before {
  background: #25d366;
  color: #fff;
}

.wrapper .tiktok:hover,
.wrapper .tiktok:hover .tooltip,
.wrapper .tiktok:hover .tooltip::before {
  background: #69c9d0;
  color: #000;
}

.adis-image {
  margin-top: 10px; /* Ajusta este valor según sea necesario */
}