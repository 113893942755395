.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #742384;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.payment-qr-code {
  width: 100%;
  margin: 0px 0;
}

.payment-container h3 {
  color: #10cbb4;
}

.payment-container p {
  color: #ffffff;
  text-align: center;
  font-size: small;
}

.payment-logo {
  height: 1.5em;
  vertical-align: text-top;
  margin-top: 5px;
}
