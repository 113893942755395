/* Estilos generales del formulario */
.adForm-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0; /* Eliminar padding para ocupar todo el espacio */
  box-sizing: border-box;
  background-color: #ffffff; /* Fondo blanco para parecer una nueva página */
  z-index: 100; /* Asegurarse de que esté por encima de otros elementos */
}

.adForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px;
  max-width: 700px; /* Limita el ancho máximo para evitar que se expanda demasiado */
  margin: 0 auto; /* Centra el formulario horizontalmente */
  background: #fff;
  box-shadow: none; /* Elimina sombras para una apariencia de página completa */
}

.adForm legend {
  font-size: 18px;
  font-weight: 700;
  color: #333;
  margin-bottom: 0px;
  text-align: center;
}

.adForm label {
  color: #333;
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.adForm select#adType,
.adForm select#category,
.adForm select#subCategory,
.adForm select#size,
.adForm input[type="text"],
.adForm textarea,
.adForm input[type="tel"],
.adForm input[type="email"],
.adForm input[type="number"],
.adForm input[type="url"],
.adForm .custom-file-upload-button {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  background: #f0f0f0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.adForm select#adType:hover,
.adForm input[type="text"]:hover,
.adForm textarea:hover,
.adForm input[type="tel"]:hover,
.adForm input[type="email"]:hover,
.adForm input[type="number"]:hover,
.adForm input[type="url"]:hover,
.adForm .custom-file-upload-button:hover {
  border-color: #4CAF50;
  background: #e9e9e9;
}

.adForm select#adType:focus,
.adForm input[type="text"]:focus,
.adForm textarea:focus,
.adForm input[type="tel"]:focus,
.adForm input[type="email"]:focus,
.adForm input[type="number"]:focus,
.adForm input[type="url"]:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.3);
}

.adForm input[type="text"]::placeholder,
textarea::placeholder {
  color: #aaa;
}

.custom-file-upload-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  transition: background 0.3s ease-in-out;
}

.custom-file-upload-button:hover {
  background-color: #45a049;
}

.preview-image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .adForm-container {
    padding: 0;
  }
  .right-sidebar {
    max-width: 100vh;
  }
  .adForm {
    padding: 0px;
  }

  .adForm input[type="text"],
  .adForm textarea,
  .adForm input[type="tel"],
  .adForm input[type="email"],
  .adForm input[type="number"],
  .adForm input[type="url"],
  .adForm select#adType,
  .adForm .custom-file-upload-button {
    font-size: 14px;
    padding: 10px;
  }

  .custom-file-upload-button {
    font-size: 14px;
  }
  .adForm legend {
    font-size: 24px;
  }
}
