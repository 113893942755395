.nav-wrapper form {
    display: flex;
    max-width: 500px;
    height: 100%;
    margin-left: auto;
    padding: 0;
}

.nav-wrapper form input[type="text"],
.nav-wrapper form button {
    padding: 0px;
}

.nav-wrapper form input[type="text"] {
    flex-grow: 1;
    margin-right: 10px;
}

.nav-wrapper form button {
    padding-right: 10px;
}