/* SearchBar.css */
.search-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 800px;
  margin: 10px auto;
  height: 60px; /* Aumenta la altura para hacerla más prominente */
  border-radius: 30px; /* Asegura que la barra de búsqueda siga siendo redonda después de aumentar la altura */
  background: rgba(0, 0, 0, 0.75); /* Fondo oscuro semitransparente */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borde semitransparente */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Añade una sombra para darle más profundidad */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Añade una transición al transform */
}

.search-bar:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Aumenta la sombra cuando se pasa el cursor sobre la barra de búsqueda */
  transform: scale(1.02); /* Aumenta ligeramente el tamaño de la barra de búsqueda cuando se pasa el cursor sobre ella */
}

.search-input {
  flex: 1;
  height: 100%;
  border: none;
  border-radius: 30px;
  padding: 0 20px;
  font-size: 1.2rem; /* Aumenta el tamaño de la fuente */
  background: transparent;
  color: #fff; /* Color del texto */
  outline: none;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Color del placeholder */
  font-size: 1rem; /* Tamaño del placeholder */
}

.search-icon,
.microphone-icon,
.clear-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7); /* Color del ícono */
  font-size: 1.5rem; /* Tamaño del ícono */
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease; /* Añade una transición al color y al tamaño del ícono */
}

.search-icon {
  right: 20px; /* Ajusta la posición para que coincida con el padding */
}

.microphone-icon {
  right: 60px; /* Ajusta la posición para que coincida con el padding */
}

.clear-icon {
  right: 100px; /* Ajusta la posición para que coincida con el padding */
}

.search-icon:hover,
.microphone-icon:hover,
.clear-icon:hover {
  color: #fff; /* Cambia el color del ícono cuando se pasa el cursor sobre él */
  transform: translateY(-50%) scale(1.2); /* Aumenta el tamaño del ícono cuando se pasa el cursor sobre él */
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 0 0 30px 30px; /* Ajusta el borde inferior para que coincida con el diseño */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 200;
}

.suggestions li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s ease;
  color: #fff; /* Color del texto */
}

.suggestions li:hover {
  background: rgba(255, 255, 255, 0.1);
}

.view-toggle-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-toggle-button:hover {
  background: rgba(0, 0, 0, 0.9);
  transform: translateY(-1px);
}

.view-toggle-button .icon {
  font-size: 1.2rem;
}

.view-toggle-button .text {
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .search-bar {
    width: 90%;
  }

  .search-icon,
  .microphone-icon,
  .clear-icon {
    display: none;
  }

  .search-input {
    padding: 0 10px;
  }
}