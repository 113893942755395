:root {
  --primary-color: #007bff;
  --hover-color: #0056b3;
  --text-color: #312f30;
  --light-text-color: #666;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --hover-shadow-color: rgba(0, 0, 0, 0.2);
  --background-color: #ffffff;
  --border-radius: 10px;
  --transition-duration: 0.3s;
}

.ad-detail-view {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: var(--background-color);
  box-shadow: -2px 0 5px var(--shadow-color);
  z-index: 1000;
  overflow-y: auto;
  transition: transform var(--transition-duration) ease;
}

.ad-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.close-button,
.nav-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color var(--transition-duration) ease;
}

.close-button:hover,
.nav-button:hover {
  color: var(--primary-color);
}

.ad-detail-content {
  padding: 1rem;
}

.ad-detail-images {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.ad-detail-image {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}

.ad-detail-image-placeholder {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-text-color);
  color: var(--background-color);
  font-size: 1.5rem;
  border-radius: var(--border-radius);
}

.ad-detail-info {
  margin-top: 1rem;
}

.ad-detail-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.ad-detail-description {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.ad-detail-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.ad-detail-location,
.ad-detail-date,
.ad-detail-business-name,
.ad-detail-contact p,
.ad-detail-metrics p {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.ad-detail-business {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.ad-detail-business-logo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.ad-detail-business-logo-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--light-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--background-color);
  font-size: 1rem;
  margin-right: 0.5rem;
}

.ad-detail-business-info {
  display: flex;
  flex-direction: column;
}

.ad-detail-business-name {
  font-size: 0.9rem;
  color: var(--text-color);
}

.ad-detail-business-type {
  font-size: 0.8rem;
  color: var(--light-text-color);
}

.ad-detail-contact p {
  display: flex;
  align-items: center;
}

.ad-detail-contact p svg {
  margin-right: 0.5rem;
}

.ad-detail-metrics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ad-detail-actions {
  display: flex;
  justify-content: space-between;
}

.action-button {
  background: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-duration) ease;
}

.action-button:hover {
  background: var(--hover-color);
}

@media (max-width: 768px) {
  .ad-detail-view {
    width: 100%;
  }
}