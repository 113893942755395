.profile-header {
    position: relative;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .cover-photo {
    width: 100%;
    height: 200px;
    background-color: #3f51b5;
  }
  
  .profile-picture {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    overflow: hidden;
    width: 120px;
    height: 120px;
    border: 4px solid white;
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .user-info h1 {
    margin-top: 80px;
    font-size: 24px;
    color: #333;
  }
  
  .user-info p {
    font-size: 18px;
    color: #777;
  }
  