.profile-settings {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-settings h2 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
  }
  
  .logout-button,
  .delete-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .logout-button {
    background-color: #f0ad4e;
    color: #fff;
  }
  
  .logout-button:hover {
    background-color: #ec971f;
  }
  
  .delete-button {
    background-color: #d9534f;
    color: #fff;
  }
  
  .delete-button:hover {
    background-color: #c9302c;
  }
  