.bottom-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #205372;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px 25px 0 0;
  padding: 10px 0;
  z-index: 100;
  height: 72px;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.bottom-nav.hidden {
  transform: translateY(100%);
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.nav-item-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: transparent;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
}

.nav-item-container.active {
  background-color: #32CD32;
  transform: translateY(-15px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.bottom-nav-icon {
  font-size: 24px;
  color: #fff;
}

.bottom-nav-text {
  font-size: 14px;
}

@media (min-width: 768px) {
  .bottom-nav {
    display: none;
  }
}
