@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.auth-container {
  padding: 20px;
  background-color: #223243;
  border-radius: 20px;
  border: 8px solid #223243;
  box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.35),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    inset 5px 5px 15px rgba(0, 0, 0, 0.35);
}

.auth-container h2 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #fff;
}

.form-group input {
  width: 100%;
  padding: 12px 20px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #223243;
  color: #fff;
  font-size: 16px;
  box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.35);
  transition: 0.3s;
}

.form-group input:focus {
  border-color: #00dfc4;
  outline: none;
}

.auth-btn {
  width: 100%;
  padding: 12px;
  background-color: #00dfc4;
  border: none;
  border-radius: 25px;
  color: #223243;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.auth-btn:hover {
  background-color: #00bfa5;
}

.toggle-auth-form {
  background: none;
  border: none;
  color: #00dfc4;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
}

.error-message {
  color: #ff0000;
  font-size: 0.9em;
  text-align: center;
  margin-top: 10px;
}

.success-message {
  color: #00dfc4;
  font-size: 0.9em;
  text-align: center;
  margin-top: 10px;
}
