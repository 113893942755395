/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-top: 50px;
  }

  .header {
    flex-direction: column;
  }

  .header-title {
    font-size: 1.2rem;
  }

  .nav-item {
    padding: 0 0px;
  }
  .wrapper {
    flex-direction: column !important;
    right: 1px !important;
    margin-bottom: 50px;
  }


  .ad-card__title {
    font-size: 16px;
  }
  .modal-title {
    font-size: 1.2rem;
  }
  .sidebar {
    display: none !important;
  }

  .portal {
    margin: 0 !important;
    max-width: initial !important;
    padding: initial !important;
  }

  button.form-close-button {
    display: block;
  }

  label,
  legend {
    color: #000;
  }
  .form-container.show {
    display: flex;
    flex-direction: column;
  }
}
