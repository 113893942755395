/* HomePage */
.container {
  display: flex;
  justify-content: space-between;
}

.portal {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0px 0;
  z-index: 0;
  position: relative;
  max-width: calc(100% - 305px);
  padding: 30px;
  padding-left: 5px;
  padding-right: 0px;
}

.right-sidebar {
  margin: auto;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Añade una sombra para darle más profundidad y un efecto de relieve */
  max-height: 100%;
  font-family: "Roboto", sans-serif;
  overflow-y: scroll;
  box-sizing: content-box;
}
/* Ajuste para eliminar espacios no deseados entre adform y adscolumn */
.right-sidebar > * {
  margin-top: 0;
  margin-bottom: 0;
}

.right-sidebar .adform,
.right-sidebar .adscolumn {
  margin: 0; /* Elimina cualquier margen por defecto */
  padding: 0; /* Opcional: ajusta el padding si es necesario */
}

.right-sidebar::-webkit-scrollbar {
  display: none; /* Para Chrome, Safari y Edge */
}
button.form-close-button {
  display: none;
}
.right-sidebar input,
.right-sidebar button {
  width: 100%; /* Asegúrate de que los elementos hijos no sean más anchos que su contenedor */
  box-sizing: border-box; /* Incluye el padding y el border en el ancho total del elemento */
}
/* Estilos para los elementos de entrada del formulario */

.right-sidebar fieldset {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}
