/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background: #e0e5ec;
  box-shadow: -2px -2px 6px #ffffff, 2px 2px 6px #babecc;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
}

.header-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-hidden {
  transform: translateY(-100%);
}

.header-left {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.hamburger-menu {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

.header-title span{
  font-size: 1.5em;
  margin-right: 10px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.logo {
  height: 50px;
  margin: 5px;
}

.counters {
  margin: 5px;
}
/* header.css */
@media (max-width: 800px) {
  .visitors .text, .advertisers .text {
    display: none;
  }
  .header-title{
    display: none;
  }
}

.visitors {
  color: #007BFF;
}

.advertisers {
  color: #28A745;
}