.adType-container {
  padding-top: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
}

/* Ruta de navegación */
.breadcrumb {
  position: relative;
  top: 0px;
  left: 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content; /* Ajusta el ancho al contenido */
}

.breadcrumb-back-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.breadcrumb-back-button:hover {
  color: #0056b3;
}

.breadcrumb-separator {
  margin: 0 5px;
}

.breadcrumb span {
  color: #007bff;
  cursor: pointer;
}

.breadcrumb span:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Contenedor de botones */
.scroll-container {
  display: flex;
  flex-wrap: wrap; /* Permite que los botones se ajusten en varias líneas */
  padding: 10px;
  gap: 15px;
  white-space: nowrap; /* Asegura que los botones se mantengan en una línea */
  overflow-x: auto; /* Habilitar el scroll horizontal si es necesario */
}

/* Estilos de los botones principales */
.scroll-container .adType-button {
  display: flex;
  flex-direction: column; /* Imagen encima del texto */
  align-items: center;
  justify-content: center;
  background:#c6c6c6;
  color: #000;
  border: none;
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background 0.3s, box-shadow 0.3s;
  width: 100px;
  height: 100px;
  white-space: normal; /* Permite que el texto se rompa en varias líneas */
}

.adType-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.scroll-container .adType-button:hover {
  transform: translateY(-5px) scale(1.05);
  background: linear-gradient(135deg, #e5e4e2 0%, #848484 100%);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.scroll-container .adType-button:active {
  transform: translateY(2px);
  box-shadow: inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff;
}

.scroll-container .adType-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Clases para los botones seleccionados */
.scroll-container .adType-button.selected-all {
  background-color: #28a745;
  color: #ffffff;
  box-shadow: 4px 4px 8px #19692c, -4px -4px 8px #37d258;
}

.scroll-container .adType-button.selected-adType {
  background-color: #007bff;
  color: #ffffff;
  box-shadow: 4px 4px 8px #0056b3, -4px -4px 8px #0099ff;
}

/* Estilos para las categorías */
.scroll-container .category-button {
  background-color: #e0e0e0;
  color: #666;
  border: none;
  border-radius: 12px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  box-shadow: 2px 2px 4px #bebebe, -2px -2px 4px #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
  white-space: nowrap;
}

.scroll-container .category-button:hover {
  background-color: #1cd7d4;
  transform: scale(1.05);
}

.scroll-container .category-button:active {
  transform: translateY(2px);
  box-shadow: inset 1px 1px 3px #bebebe, inset -1px -1px 3px #ffffff;
}

.scroll-container .category-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.selected-category {
  background-color: #f9cc00;
  color: #ffffff;
}

/* Botón de descarga de la App */
.download-app-button {
  background: linear-gradient(135deg, #ff7e5f 0%, #feb47b 100%);
}

.download-app-button:hover {
  background: linear-gradient(135deg, #e6724d 0%, #fc9a5a 100%);
}

span.adType-label {
  font-size: 1rem;
  text-align: center;
}

/* Animaciones */
.fade-in {
  animation: fadeIn 0.6s forwards;
}

.fade-out {
  animation: fadeOut 0.6s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

@keyframes fadeOut {
  from { opacity: 1; transform: scale(1); }
  to { opacity: 0; transform: scale(0.95); }
}

/* Estilos para el título seleccionado */
.selected-title {
  display: flex;
  flex-direction: row; /* Cambiado a fila */
  align-items: center; /* Alineado al centro verticalmente */
  justify-content: flex-start; /* Alineado a la izquierda */
  height: auto; /* Ajusta según sea necesario */
  background: rgba(37, 117, 252, 0.1);
  border-bottom-left-radius: 25px; 
  border-bottom-right-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  padding: 16px;
  animation: fadeIn 0.6s forwards;
}

.selected-title .selected-icon {
  width: 80px;
  height: 80px;
  margin-right: 20px; /* Espacio entre el icono y el título */
  /* animation: rotateIcon 2s infinite linear; */
}

@keyframes rotateIcon {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.selected-title h1 {
  font-size: 2rem; /* Ajustado para ocupar menos espacio horizontal */
  color: #1d62dd;
  text-transform: capitalize;
  animation: slideDown 0.6s forwards;
}

@keyframes slideDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Estilos para las secciones de categorías y subcategorías */
.category-button, .subcategory-button {
  padding: 2px 8px;
  background-color: #f9cc00;
  color: #000000;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 0.9rem;
}

.category-button:hover, .subcategory-button:hover {
  background-color: #9575cd;
  transform: scale(1.05);
}

.selected-category {
  background-color: #7e57c2;
  color: #ffffff;
}

/* Responsividad */
@media (max-width: 768px) {
  .scroll-container {
    flex-wrap: nowrap; /* Cambiado de wrap a nowrap (no modificar esto) Asegura que los botones se mantengan en una línea */
    max-width: 100vw; /* Asegura que ocupe todo el ancho de la pantalla */
    overflow-x: scroll; /* Habilitar el scroll horizontal */
    padding: 0px 0px 8px 10px; /* Ajustado para espacio de elementos fijos */
  }

  .adType-button {
    width: 100%;
    max-width: 300px;
    height: auto; /* Ajusta la altura automáticamente */
  }

  .selected-title {
    height: auto;
    flex-direction: row;
    text-align: center;
    gap: 15px;
  }

  .selected-title .selected-icon {
    margin-right: 0;
  }

  .breadcrumb {
    left: 10px;
  }

  .breadcrumb-back-button {
    font-size: 1.2rem;
  }
}

/* Ajustes Adicionales para Nuevos Tipos de Anuncios */
#adTypeButtons .adType-section-inner {
  overflow: hidden; /* Asegura que no haya desbordamiento */
}

#adTypeButtons .subcategory-section-inner {
  overflow: hidden;
}