body {
  zoom: 0.9;
  display: grid;
  grid-template-areas:
    "header"
    "main";
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  margin: 0;
  /* background: linear-gradient(270deg, #4c1a57, #00a8aa, #333333); */
  background-color: rgb(240, 248, 255);
  background: linear-gradient(145deg, rgba(240,230,214,0.5) 25%, rgba(230,220,200,0.5) 25%, rgba(230,220,200,0.5) 50%, rgba(240,230,214,0.5) 50%, rgba(240,230,214,0.5) 75%, rgba(230,220,200,0.5) 75%, rgba(230,220,200,0.5) 100%);

  background-size: 600% 600%;
  animation: AnimationName 15s ease infinite;
  background-color: rgb(240, 248, 255);
  cursor: url(../images/cursor-lupa.png), auto;
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* body {
    height: 100vh;
    background: 
        radial-gradient(circle at 20% 50%, #333333, #111111),
        radial-gradient(circle at 80% 50%, #333333, #111111);
    background-blend-mode: screen;
} */
/* body{

    background: var(--background-color) url("../images/hero-bg.jpg") center/cover;
} */

/* Estilos para el modo oscuro */
.dark-mode body {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) { 
  body { 
    zoom: 1; 
  } 
}