:root {
    --main-color: #12b2b8;
    --secondary-color: #0b649c;
    --font-color: #333;
    --placeholder-color: #999;
    --background-color: #f0fcfe;
    --font-family: "Poppins", sans-serif;
    --input-border: 1px solid #ccc;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");